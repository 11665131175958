import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { InterfaceToastItem } from '../toaster.model';

export const AnimationToast = [
  trigger('items', [
    transition(':enter', [
      style({ transform: 'scale(0.5)', opacity: 0 }), // initial
      animate(
        '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({ transform: 'scale(1)', opacity: 1 })
      ),
    ]),
    transition(':leave', [
      style({ transform: 'scale(1)', opacity: 1, height: '*' }),
      animate(
        '1s cubic-bezier(.8, -0.6, 0.2, 1.5)',
        style({
          // margin: '0px',
          opacity: 0,
          // transform: 'scale(0.5)',
          // height: '0px',
        })
      ),
    ]),
  ]),
];

@Component({
  selector: 'ds-toasts',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: AnimationToast,
})
export class ToastContainerComponent {
  messages: InterfaceToastItem[] = [];

  constructor(private _cdr: ChangeDetectorRef) {}

  removeItem(index) {
    this.messages.splice(index, 1);
    this._cdr.detectChanges();
  }

  removeAllItems() {
    this.messages = [];
    this._cdr.detectChanges();
  }

  addItem(item: InterfaceToastItem) {
    const existingMessageIndex = this.messages.findIndex(
      (messageItem) => messageItem.key === item.key
    );
    if (item.key && existingMessageIndex >= 0) {
      return existingMessageIndex;
    }
    const index = this.messages.push(item);
    this._cdr.detectChanges();
    return index - 1;
  }
}
